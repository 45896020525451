import React from 'react';
import { Flex, Text, Image, Heading } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import Step1Icon from '../assets/steps/Step1Icon.png';
import Step2Icon from '../assets/steps/Step2Icon.png';
import Step3Icon from '../assets/steps/Step3Icon.png';
import Step4Icon from '../assets/steps/Step4Icon.png';
import Wave1 from '../assets/Wave1';
import Wave2 from '../assets/Wave2';

import { H2, H4 } from '@typography';
import { cloudfrontBaseUrl } from '../../lib/consts';
import HowItWorksItem from '../zeta/HowItWorksItem';

const HowItWorks = () => {
  const JointHowItWorks1 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks1.svg`;
  const JointHowItWorks2 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks2.svg`;
  const JointHowItWorks3 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks3.svg`;

  return (
    <Flex py={[10, 24, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[10, 10, 10, 16]}
        px={[4, 4, 4, 0]}
      >
        <H2>How it works</H2>
        <H4 mt={4} center>
          Ready to get started? Here's what to expect.
        </H4>
      </Flex>

      <Flex direction="column" mt={4}>
        <Flex
          align="flex-start"
          justify={['center', 'center', 'center', 'flex-end']}
          direction={['column', 'column', 'column', 'row']}
          marginLeft={[0, 0, 0, '5%']}
          px={[4, 4, 4, 0]}
        >
          <HowItWorksItem
            image={JointHowItWorks1}
            title="Apply"
            body="Tell us about yourself in a quick online process"
            connector="even"
          />
          <HowItWorksItem
            image={JointHowItWorks2}
            title="Invite 1 (or more) to join"
            body="Invite at least one person to join you on Zeta."
            connector="odd"
          />
          <HowItWorksItem
            image={JointHowItWorks3}
            title="Save & spend together"
            body="Everyone receives a debit card with shared account access."
            connector="none"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HowItWorks;
