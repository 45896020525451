import React, { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { navigate } from 'gatsby';
import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH, APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';
import { colors } from '@theme';
import TopSection from '../components/jointAccount/TopSection';
import Metadata from '../components/jointAccount/Metadata';
import JointKeyFeatures from '../components/jointAccount/jointKeyFeatures';
import CardFeatures from '../components/jointAccount/cardFeatures';
import HowItWorks from '../components/jointAccount/howItWorks';
import FAQs from '../components/jointAccount/FAQs';
import Anchor from '../components/jointAccount/anchor';
import { JAFAQContent } from '../lib/consts';
import VIP from '../components/jointAccount/VIP';
import CouplesLoveZeta from '../components/jointAccount/CouplesLoveZeta';
import HelpfulResources from '../components/jointAccount/HelpfulResources';

const JointAccount = props => {
  const navLoaded = props?.navLoaded;
  const tabIndex = props?.tabIndex;
  const { statusBarColor } = props;

  useEffect(() => {
    const windowPathname = props?.location?.pathname?.toString();

    if (windowPathname === '/joint-bank-account' && !navLoaded) {
      navigate('/page-select', { state: { dest: 'joint-bank-account' } });
    }
  }, [navLoaded]);

  return (
    <>
      {!navLoaded ? (
        <Box
          background={'linear-gradient(to bottom, #E9F8FB 0%, #E9F8FB 20%, #fff 100%)'}
          height={'100vh'}
        />
      ) : (
        <Box mode="light" newNav noRootContainer>
          {tabIndex === 1 ? <Metadata statusBarColor={statusBarColor} /> : null}
          <Flex
            className="main-column"
            width="100%"
            justifyContent="center"
            direction="column"
          >
            <TopSection />

            <Flex mb={['50px', '82px']} justify="center">
              <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
                <JointKeyFeatures />
              </ContentContainer>
            </Flex>

            <VIP {...props} />

            <Flex bg={colors.white} className="theres-even-more-to-love">
              <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
                <CardFeatures />
              </ContentContainer>
            </Flex>

            <CouplesLoveZeta />

            <Flex justify="center" className="how-it-works">
              <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
                <HowItWorks variant="joint-accounts" />
              </ContentContainer>
            </Flex>

            <Flex bg={colors.background} className="FAQs">
              <FAQs content={JAFAQContent} />
            </Flex>

            <Flex
              background={'linear-gradient(180deg, #fff, #EAF9FB)'}
              className="helpful-resources"
            >
              <ContentContainer
                maxWidth={['100%', 'fit-content', '100%', APP_CONTENT_DESKTOP_WIDTH]}
                minWidth={'auto'}
              >
                <HelpfulResources />
              </ContentContainer>
            </Flex>

            <Anchor />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default JointAccount;
