import React from 'react';
import { Flex, Image, Box } from '@chakra-ui/core';
import ContentContainer from '../../components/ContentContainer';
import {
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_WIDE_WIDTH,
} from '../../styles/sizes';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { H2, H4 } from '@typography';

const CouplesLoveZeta = () => {
  const CouplesLoveZeta1 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta1.svg`;
  const CouplesLoveZeta2 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta2.svg`;
  const CouplesLoveZeta3 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta3.svg`;
  const CouplesLoveZeta4 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta4.svg`;
  const CouplesLoveZeta5 = `${cloudfrontBaseUrl}/homepage-24/home-familiesHeartZeta5.svg`;
  return (
    <Box
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
      background={[
        `radial-gradient(90% 90% at -10% -10%, #98D3B666 0%, transparent), 
        radial-gradient(90% 90% at top right, #F2D02244 0%, transparent), 
        radial-gradient(90% 90% at 90% 50%, #FAE6E666 0%, transparent), 
        radial-gradient(90% 90% at bottom left, #6EDAF266 0%, transparent)`,
        `radial-gradient(90% 90% at top left, #98D3B666 0%, transparent), 
          radial-gradient(90% 90% at top right, #F2D02266 0%, transparent), 
          radial-gradient(90% 90% at right, #FAE6E666 0%, transparent), 
          radial-gradient(90% 90% at bottom left, #6EDAF266 0%, transparent)`,
        `radial-gradient(
            65% 90% at 100% 90%,
            #fae4e411 25%,
            #fae4e455 70%,
            transparent
          ),
          radial-gradient(90% 90% at top left, #98d3b666 0%, transparent),
          radial-gradient(60% 50% at top right, #6edaf255 0%, transparent),
          radial-gradient(75% 90% at bottom right, #fae4e466 10%, transparent),
          radial-gradient(90% 90% at bottom left, #f2d02255 0%, transparent);`,
        ` radial-gradient(
            65% 90% at 100% 90%,
            #fae4e411 25%,
            #fae4e455 70%,
            transparent
          ),
          radial-gradient(90% 90% at top left, #98d3b666 0%, transparent),
          radial-gradient(60% 50% at top right, #6edaf255 0%, transparent),
          radial-gradient(75% 90% at bottom right, #fae4e466 10%, transparent),
          radial-gradient(90% 90% at bottom left, #f2d02255 0%, transparent);`,
      ]}
    >
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
        <Flex paddingY={[12, 24, 24, 24]} direction="column" justify={'center'}>
          <Flex
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
            mb={[10, 10, 16]}
            px={4}
          >
            <H2>Couples 🖤︎ Zeta</H2>
            <H4 center mt={4} maxWidth={APP_CONTENT_SMALL_DESKTOP_WIDTH}>
              Zeta's aim is to help you win, no matter what stage you are at in your
              relationship or life. Here's what our customers have to say.
            </H4>
          </Flex>

          <Flex
            justify="center"
            alignItems="center"
            alignSelf={['center', 'center', 'auto']}
            direction={['column', 'column', 'row']}
            paddingX={['24px']}
            paddingTop="24px"
            mb={[0, 8]}
            style={{ gap: '12px' }}
          >
            <Flex width="100%" flexDirection={'column'} style={{ gap: '12px' }}>
              <Image width="100%" src={CouplesLoveZeta1} />
              <Image width="100%" src={CouplesLoveZeta2} />
            </Flex>
            <Flex width="100%" flexDirection={'column'} style={{ gap: '12px' }}>
              <Image width="100%" src={CouplesLoveZeta3} />
            </Flex>
            <Flex width="100%" flexDirection={'column'} style={{ gap: '12px' }}>
              <Image width="100%" src={CouplesLoveZeta4} />
              <Image width="100%" src={CouplesLoveZeta5} />
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </Box>
  );
};

export default CouplesLoveZeta;
