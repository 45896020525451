import React, { useEffect, useState } from 'react';
import { Flex, useMediaQuery, Text, Image, Box } from '@chakra-ui/core';
import Lottie from 'react-lottie';
import ContentContainer from '../../components/ContentContainer';
import JointAccountAnimation from '../../lotties/joint-1-animation-2.json';
import JointAccountMobileAnimation from '../../lotties/joint-account-mobile-animation.json';
import {
  APP_CONTENT_MOBILE_WIDTH,
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
} from '../../styles/sizes';
import { cloudfrontBaseUrl } from '../../lib/consts';

const TopSection = () => {
  const [showAppStore, setShowAppStore] = useState(false);
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_MOBILE_WIDTH})`);
  const JointHeroLeft = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-hero-left.png`;
  const JointHeroRight = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-hero-right.png`;

  useEffect(() => {
    if (typeof window !== undefined) {
      const search = window.location.search.substring(1);
      if (search.indexOf('aff=true')) {
        setShowAppStore(false);
      }
    }
  }, []);

  return (
    <Flex paddingTop={[16, 16, 24, 24]} justifyContent={'center'}>
      <Flex direction={'column'}>
        <Flex
          maxWidth={APP_CONTENT_SMALL_DESKTOP_WIDTH}
          style={{ gap: 12 }}
          width={'100%'}
          direction={['column-reverse', 'column-reverse', 'row', 'row']}
          alignItems={['center', 'center', 'inherit', 'inherit']}
        >
          <Box
            width={['50%', '50%', '30%', '30%']}
            display={'flex'}
            alignItems={'center'}
            alignSelf={['flex-start', 'flex-start', 'unset', 'unset']}
            marginLeft={['-12px', 0, 0, 0]}
          >
            <Image width="100%" src={JointHeroLeft} />
          </Box>
          <Box width={['70%', '70%', '40%', '40%']} paddingLeft={[0, 0, '8px', '8px']}>
            <Lottie
              isClickToPauseDisabled
              style={{
                cursor: 'auto',
              }}
              options={{
                loop: true,
                autoplay: true,
                animationData: isMobile ? JointAccountAnimation : JointAccountAnimation,
              }}
            />
          </Box>
          <Box
            width={['50%', '50%', '30%', '30%']}
            display={'flex'}
            alignItems={'flex-start'}
            alignSelf={['flex-end', 'flex-end', 'unset', 'unset']}
            marginRight={['-18px', 0, 0, 0]}
          >
            <Image width="100%" paddingTop={[0, 0, 12, 12]} src={JointHeroRight} />
          </Box>
        </Flex>
        <Flex align="center" marginBottom="84px" marginTop="20px" justify="center">
          <Text
            ml={4}
            fontStyle="italic"
            width="230px"
            fontSize={'10px'}
            style={{ textAlign: 'center' }}
          >
            Zeta Accounts are deposit accounts powered by Piermont Bank, Member FDIC.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TopSection;
